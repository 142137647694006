import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [formType, setFormType] = useState('login'); // 'login' or 'signup'
  const [signupData, setSignupData] = useState({ username: '',  email: '', password: '' });
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [emailData, setEmailData] = useState({ to: '', subject: '', text: '' });
  const [token, setToken] = useState('');

  const handleSignupChange = (e) => {
    const { name, value } = e.target;
    setSignupData({ ...signupData, [name]: value });
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://server.techozon.com/register', signupData);
      alert('Signup successful. Please log in.');
      setFormType('login');
    } catch (error) {
      alert('Error signing up: ' + error.response.data);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://server.techozon.com/login', loginData);
      setToken(response.data.token);
      alert('Login successful');
    } catch (error) {
      alert('Error logging in: ' + error.response.data);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://server.techozon.com/send-email', emailData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Email sent successfully');
    } catch (error) {
      alert('Error sending email: ' + error.response.data);
    }
  };

  return (
    <div className="App">
      {!token ? (
        formType === 'signup' ? (
          <form onSubmit={handleSignupSubmit}>
            <h1>Sign Up</h1>
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={signupData.username}
              onChange={handleSignupChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={signupData.email}
              onChange={handleSignupChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={signupData.password}
              onChange={handleSignupChange}
              required
            />
            <button type="submit">Sign Up</button>
            <p>Already have an account? <button type="button" onClick={() => setFormType('login')}>Log In</button></p>
          </form>
        ) : (
          <form onSubmit={handleLoginSubmit}>
            <h1>Login</h1>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={loginData.email}
              onChange={handleLoginChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={loginData.password}
              onChange={handleLoginChange}
              required
            />
            <button type="submit">Login</button>
            <p>Don't have an account? <button type="button" onClick={() => setFormType('signup')}>Sign Up</button></p>
          </form>
        )
      ) : (
        <form onSubmit={handleEmailSubmit}>
          <h1>Send Email</h1>
          <input
            type="email"
            name="to"
            placeholder="To"
            value={emailData.to}
            onChange={handleEmailChange}
            required
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={emailData.subject}
            onChange={handleEmailChange}
            required
          />
          <textarea
            name="text"
            placeholder="Message"
            value={emailData.text}
            onChange={handleEmailChange}
            required
          />
          <button type="submit">Send Email</button>
        </form>
      )}
    </div>
  );
}

export default App;
